import React from "react"
import { ThemeProvider } from "styled-components"

import { MobileProvider } from "./src/providers/mobile/mobile.provider"
import { ModalProvider } from "./src/providers/modal/modal.provider"
import { AlertBannerProvider } from "./src/providers/alert-banner/alert-banner.provider"
import { PinnedCTAProvider } from "./src/providers/pinnedCTA/pinnedCTA.provider"
import { ShortPathProvider } from "./src/providers/short-path/short-path.provider"

import Modal from "./src/components/modal/modal.component"

import "./src/layouts/page/base.css"
import theme from "./src/themes/theme"
import "@fontsource/barlow" // Defaults to weight 400.
import "@fontsource/barlow/300.css" // Weight 300.
import "@fontsource/barlow/600.css" // Weight 600.
import "@fontsource/barlow/700.css" // Weight 700.
import "@fontsource/barlow/400-italic.css"
import "@fontsource/barlow/600-italic.css" // Weight 600 // Font-Style Italic
import { BlogSidebarProvider } from "./src/providers/blog-sidebar/blog-sidebar.provide"

require("prismjs/themes/prism-tomorrow.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")

export const onClientEntry = async () => {
  if (!(`IntersectionObserver` in window)) {
    await import(`intersection-observer`)
  }
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <React.Fragment>
      {element}
      <Modal />
    </React.Fragment>
  )
}

// Injecting third-party scripts in onInitialClientRender
export const onInitialClientRender = () => {
  // Ensure the dataLayer exists
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments)
  }
  window.gtag("js", new Date())
  window.gtag("config", "G-3V7G2575P1")

  // Stape Custom Loader script
  const stapeScript = document.createElement("script")
  stapeScript.innerHTML = `
    !function(){"use strict";function l(e){for(var t=e,r=0,n=document.cookie.split(";");r<n.length;r++){var o=n[r].split("=");if(o[0].trim()===t)return o[1]}}function s(e){return localStorage.getItem(e)}function u(e){return window[e]}function A(e,t){e=document.querySelector(e);return t?null==e?void 0:e.getAttribute(t):null==e?void 0:e.textContent}var e=window,t=document,r="script",n="dataLayer",o="https://sync.zerotomastery.io",a="",i="6qfrzmeosmc",c="8k=aWQ9R1RNLU1aQ0xHTlI%3D&page=2",g="stapeUserId",v="",E="",d=!1;try{var d=!!g&&(m=navigator.userAgent,!!(m=new RegExp("Version/([0-9._]+)(.*Mobile)?.*Safari.*").exec(m)))&&16.4<=parseFloat(m[1]),f="stapeUserId"===g,I=d&&!f?function(e,t,r){void 0===t&&(t="");var n={cookie:l,localStorage:s,jsVariable:u,cssSelector:A},t=Array.isArray(t)?t:[t];if(e&&n[e])for(var o=n[e],a=0,i=t;a<i.length;a++){var c=i[a],c=r?o(c,r):o(c);if(c)return c}else console.warn("invalid uid source",e)}(g,v,E):void 0;d=d&&(!!I||f)}catch(e){console.error(e)}var m=e,g=(m[n]=m[n]||[],m[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"}),t.getElementsByTagName(r)[0]),v=I?"&bi="+encodeURIComponent(I):"",E=t.createElement(r),f=(d&&(i=8<i.length?i.replace(/([a-z]{8}$)/,"kp$1"):"kp"+i),!d&&a?a:o);E.async=!0,E.src=f+"/"+i+".js?"+c+v,null!=(e=g.parentNode)&&e.insertBefore(E,g)}();
  `
  document.body.appendChild(stapeScript)

  // Unbounce script
  const ubembedScript = document.createElement("script")
  ubembedScript.src = "https://a44cc6ffd004438ab74297783b3e5fc9.js.ubembed.com"
  ubembedScript.defer = true
  ubembedScript.referrerPolicy = "origin"
  document.body.appendChild(ubembedScript)

  // Trustpilot script
  const trustpilotScript = document.createElement("script")
  trustpilotScript.async = true
  trustpilotScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
  document.body.appendChild(trustpilotScript)
}

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <MobileProvider>
          <PinnedCTAProvider>
            <ShortPathProvider>
              <BlogSidebarProvider>
                <AlertBannerProvider>{element}</AlertBannerProvider>
              </BlogSidebarProvider>
            </ShortPathProvider>
          </PinnedCTAProvider>
        </MobileProvider>
      </ModalProvider>
    </ThemeProvider>
  )
}
